import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 19.5 will be announced at 8:00pm tonight.  It
will be Friday’s class wod and we’ll throwdown from 4:00-7:00pm Friday
night!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule started this week!  Evening CrossFit classes
are now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new Ville Bootcamp started this week as well!  These classes
will be Tues-Friday at 9:30am.  The rest of this month is free for non
members so spread the word if you know of anyone that may want to try it
out. This class is included in your unlimited membership. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      